@media (min-width: 1300px) {
	.container {
	  max-width: 1280px;
	}
  }
  
  /*@media (min-width: 992px) {
	  .navbar-toggler {
		  display: none;
	  }
	  .btn.close-btn {
		  display: none;
	  }
	  
  }*/
  @media (min-width: 991px) {
	.trers-grid-image {
	  img {
		max-width: 150px;
	  }
	}
  }
  
  @media (max-width: 1199px) {
	footer{
        .footer-links {
            display: block;
        }
    }
	.free-shipping-section {
	  ul {
		flex-flow: wrap;
		li {
		  margin-bottom: 15px;
		}
	  }
	}
	body {
	//   padding-top: 47px;
	  padding-top: 0px;
	}
  
	.header {
	  .flex-container {
		.header-row-one {
		  display: block;
		  .account-section {
			position: absolute;
			top: 0;
			width: 100%;
			border-bottom: 1px solid #00000012;
			left: 0;
			z-index: 99;
			background: #fff;
			.account {
			  justify-content: flex-end;
    		 margin-top: 10px;
			}
		  }
		}
	  }
	}
	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 100%;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
	  }
	  
	  .dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	  }
	  
	  .dropdown-content a:hover {
		background-color: #ddd;
	  }
	  
	  .dropdown:hover .dropdown-content {
		display: block;
	  }
  }
  @media (max-width: 991px) {

	.product-details-wrapper {
		.slider-nav {
			bottom:6px;
			
		}
		.details-wrapper {
			
			.product-all-details {
				.row {
					.col-left {
						flex: auto;
					}
				}
				.product-addtional-details{
					padding-bottom:15px;
					&:before {
						width:100%;
					}
				}
			}
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 30px;
		  }
		}
	  }
	/**Product**/
	.product-box-inner {
	  .product-image {
		max-width: 150px;
	  }
	  .product-detail {
		.price-wrapper {
		  position: inherit;
		  transform: none;
		}
		.add-to {
		  position: inherit;
		}
	  }
	  .verified {
		top: 10px;
	  }
	}
  
	.best-products {
	  .row-fluid {
		display: block;
		.tyers-grid {
		  justify-content: space-between;
		  li {
			display: inline-block;
			margin: 15px 0px 15px 0px;
			text-align: center;
		  }
		}
	  }
	  .column {
		&.single-image {
		  margin-top: 15px;
		}
	  }
	}
  }
  @media (min-width: 640px) {
	.best_view_cart{
		width: 24% !important;
		left: 0px!important;
	}
.best_after-cart{
	width: 90%;
	display: flex;
	justify-content: space-between;
}
  }
  
  @media (max-width: 640px) {
	.header-section4{
		margin-top: 100px !important;
	}
	.best_cart_button{
		width:45% !important;
	}
	.best_view_cart{
		width: 40% !important;
		left: 0px!important;
	}
	.best_after-cart{
		width: 90%;
		display: flex;
		justify-content: space-between;
	}
	  }
	  
  @media (max-width: 767px) {
	.mob-filter{
		
		z-index: 200;
		background-color: $bg-color;
		width: 100%;
		top: 0;
    	bottom:0;
		left:0;
    	position:fixed;
    	overflow-y:scroll;
    	overflow-x:hidden;
	}

	.filter-button{
		display: block !important;
		position: fixed;
    	bottom: 4%;
    	left: 45%;
   		width: 50%;
    	height: 8%;
		border-radius: 50px;
		border: 2px solid $primary;
		font-size: 18px;
		font-weight: 600;
		-webkit-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		-moz-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
	}


	.details-wrapper {
		padding-right: 0px !important;
    	padding-left: 0px !important;
    	margin-right: 0px !important;
    	margin-left: 0px !important;
		.slider-nav {
			bottom:6px;
			max-width: 240px;
			min-width: 230px;
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 20px;
		  }
		}
	  }
	.aside-group {
	  display: block;
	}
  
	/**Title**/
	.content-title {
	  .title-box {
		.title {
		  &:after {
			display: none;
		  }
		}
	  }
	}
  
	.header {
	  .flex-container {
		display: block;
		.logo {
		  text-align: center;
		//   padding: 15px;
		  margin:0px auto;
		}
		.header-row-one {
			.search-bar-group {
				.search-bar {
					margin-top:10px;
					margin-right: -4rem;
					width:100% !important;
					
				}
			}
		}
	  }
	}
  
	.content-1-section {
	  position: static;
	  border-radius: 0px;
	}
	.homepage-banner-slider {
	  .owl-theme {
		.owl-dots {
		  bottom: 0;
		}
	  }
	}
  
	.best-products {
	  .column {
		.tyers-grid {
		  li {
			display: block;
			text-align: center;
		  }
		}
	  }
	}
  }
  @media (max-width: 640px) {

	.checkout-btn{
		min-width:fit-content; 
		padding-left:0px !important; 
		height:30px !important;
		position: relative;
		top: 0px;
		width: 150px !important;
		margin-top: 16%;
	}
	
	.collection-wrapper {
	  .col-grid-box {
		padding: 0px 10px 0px 10px;
	  }
	}
	
	footer{
        .footer-links {
            display: grid !important;
        }
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
			min-width:136%;
		}
    }

	.banner-content {
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}
	.header {
	  .flex-container {
		.header-row-one {
		  .search-bar {
			margin-left: 0px;
		  }
		  .account-section{
			position: fixed !important;
			z-index: 2 !important;
			padding-right:0px;
			.account{
				margin-right: 10px !important;
			}
			.dropdown-toggle{
				font-size:10px !important;
			}
			.fa-user{
				font-size:10px !important
			}
		  }
		}
	  }
	}


	.product-box-inner {
	  display: block;
	  padding: 10px 0px 10px 0;
	  .img-wrapper {
		padding-bottom: 10px;
		.product-image {
		  max-width: 100%;
		  width: 100%;
		}
	  }
  
	  .product-detail {
		padding-left: 0px;
	  }
	  .verified {
		top: 10px;
		transform: none;
	  }
	}
  
	/*Pagenation**/
	.product-pagination {
	  .row {
		display: block;
		> div {
		  margin-bottom: 10px;
		  text-align: center;
		  p {
			margin-bottom: 0px;
		  }
		  .pagination {
			justify-content: center;
		  }
		}
	  }
	}
	.mobile-filter{
		display: block !important;
	  }
	.desktop-filter{
		display : none !important;
	}
	.mob-categories{
		
		opacity: 1;
		padding:10px;
		font-family: 'proxima-regular','sans-serif'!important;
		position: fixed;
		background-color: white;
		z-index: 100;
		width: 100%;
		height: 100%;
		border-right: 50px solid rgba(0, 0, 0, 0.5);
		overflow-y: auto;
		top:0;
		left:0;
		background-clip: padding-box;
		
		
	}
	.touch-out{
		 background-color:transparent;
		 position: fixed;
		 z-index: 999;
		 width: 60px;
		 right: 0px;
		 height:300%;
		 top:0px;
		 overflow:hidden;
		
	}
	// body{
	// 	position: fixed !important;
	// }

  }
  /*** Range **/
  
  @media (max-width: 991px) and (min-width: 640px) {
	.product-name {
	  font-size: 18px;
	}
	.price {
	  font-size: 18px;
	}
  
	.product-price {
	  .tax-includes {
		font-size: 13px;
	  }
	}
	.verified {
	  font-size: 13px;
	}
	.add-to {
	  .btn-solid {
		font-size: 15px;
	  }
	}
  }


  @media (max-width: 1920px) {
	.shipping1{
		display: flex!important;
	}

	.shipping2{
		display: none!important;
	}

	.video1{
		display: block!important;
	}

	.video2{
		display: none!important;
	}
  }


  
@media (min-width: 360px) and (max-width: 600px) {
	.shipping1{
		display: none!important;
	}

	.shipping2{
		display: flex!important;
	}

	.video1{
		display: none!important;
	}

	.video2{
		display: block!important;
	}
}
  